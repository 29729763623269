<template>
  <ion-page>
    <ion-content>
      <div>
        <ion-list-header>
          <ion-label color="primary"
            >取得済みのSランクこころを選択してください</ion-label
          >
        </ion-list-header>
        <table>
          <tr v-for="row in gridIcons" :key="row.length">
            <td v-for="u of row" :key="u.id" style="width: 120px">
              <div style="text-align: center">
                <img
                  :id="u.id"
                  :src="u.url"
                  v-on:click="iconClick(u.id)"
                  :title="u.name"
                  :class="u.class"
                />
                <div class="namelabel">
                  {{ u.name }}
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </ion-content>
  </ion-page>
</template>

<style>
.namelabel {
  font-size: 10px;
  font-weight: bold;
  color: white;
  background: #333333;
  border-color: blue;
  border-style: solid;
  border-width: 2px;
  border-radius: 4px;
  margin: 1px;
}
.icon {
  width: 64px;
  transition-duration: 0.1s;
  /* filter: grayscale(1); */
}
/* .icon:hover {
  transform: scale(1);
} */
.selected {
  opacity: 1;
}
.unselected {
  opacity: 0.4;
}
</style>

<script lang="ts">
import { IonContent, IonPage, IonLabel, IonListHeader } from "@ionic/vue";
import { reactive, computed } from "vue";

import ks from "@/assets/KokoroStatus.json";

export default {
  name: "Tab2",
  components: {
    IonContent,
    IonPage,
    IonLabel,
    IonListHeader,
  },
  setup() {
    const state = reactive({
      count: 0,
      selColor: "all",
    });

    const gridIcons = computed(() => {
      const sel: string[] = JSON.parse(localStorage.selectedIcon ?? "[]");
      const h = ks
        .filter((v) => {
          return v.exclude == false;
        })
        .filter((v) => {
          return state.selColor == "all" || v.color == state.selColor;
        })
        .sort((a, b) => {
          return b.cost - a.cost;
        })
        .map((v) => {
          v["id"] = v.icon;
          v["url"] = "images/monstericon/" + v.icon + ".webp";
          v["class"] = [
            "icon",
            sel.indexOf(v.icon) > -1 ? "selected" : "unselected",
          ];
          return v;
        });

      const gh = [];
      const colSize = 4;
      for (let i = 0; i < h.length; i += colSize) {
        gh.push(h.slice(i, i + colSize));
      }
      return gh;
    });

    const icons = computed(() => {
      console.log("computed");
      const sel: string[] = JSON.parse(localStorage.selectedIcon ?? "[]");
      state.count = sel.length;
      const h = ks
        .filter((v) => {
          return v.exclude == false;
        })
        .filter((v) => {
          return state.selColor == "all" || v.color == state.selColor;
        })
        .sort((a, b) => {
          return b.cost - a.cost;
        })
        .map((v) => {
          v["id"] = v.icon;
          v["url"] = "assets/monstericon/" + v.icon + ".webp";
          v["class"] = [
            "icon",
            sel.indexOf(v.icon) > -1 ? "selected" : "unselected",
          ];
          return v;
        });
      return h;
    });

    function iconClick(index: string) {
      let sel: string[] = JSON.parse(localStorage.selectedIcon ?? "[]");
      const p = document.getElementById(index);

      if (sel.indexOf(index) == -1) {
        sel.push(index);
        p!.className = "icon selected";
      } else {
        sel = sel.filter((v) => v !== index);
        p!.className = "icon unselected";
      }
      localStorage.selectedIcon = JSON.stringify(sel);
      state.count = sel.length;
    }

    return {
      state,
      icons,
      gridIcons,
      iconClick,
    };
  },
};
</script>