
import { IonContent, IonPage, IonLabel, IonListHeader } from "@ionic/vue";
import { reactive, computed } from "vue";

import ks from "@/assets/KokoroStatus.json";

export default {
  name: "Tab2",
  components: {
    IonContent,
    IonPage,
    IonLabel,
    IonListHeader,
  },
  setup() {
    const state = reactive({
      count: 0,
      selColor: "all",
    });

    const gridIcons = computed(() => {
      const sel: string[] = JSON.parse(localStorage.selectedIcon ?? "[]");
      const h = ks
        .filter((v) => {
          return v.exclude == false;
        })
        .filter((v) => {
          return state.selColor == "all" || v.color == state.selColor;
        })
        .sort((a, b) => {
          return b.cost - a.cost;
        })
        .map((v) => {
          v["id"] = v.icon;
          v["url"] = "images/monstericon/" + v.icon + ".webp";
          v["class"] = [
            "icon",
            sel.indexOf(v.icon) > -1 ? "selected" : "unselected",
          ];
          return v;
        });

      const gh = [];
      const colSize = 4;
      for (let i = 0; i < h.length; i += colSize) {
        gh.push(h.slice(i, i + colSize));
      }
      return gh;
    });

    const icons = computed(() => {
      console.log("computed");
      const sel: string[] = JSON.parse(localStorage.selectedIcon ?? "[]");
      state.count = sel.length;
      const h = ks
        .filter((v) => {
          return v.exclude == false;
        })
        .filter((v) => {
          return state.selColor == "all" || v.color == state.selColor;
        })
        .sort((a, b) => {
          return b.cost - a.cost;
        })
        .map((v) => {
          v["id"] = v.icon;
          v["url"] = "assets/monstericon/" + v.icon + ".webp";
          v["class"] = [
            "icon",
            sel.indexOf(v.icon) > -1 ? "selected" : "unselected",
          ];
          return v;
        });
      return h;
    });

    function iconClick(index: string) {
      let sel: string[] = JSON.parse(localStorage.selectedIcon ?? "[]");
      const p = document.getElementById(index);

      if (sel.indexOf(index) == -1) {
        sel.push(index);
        p!.className = "icon selected";
      } else {
        sel = sel.filter((v) => v !== index);
        p!.className = "icon unselected";
      }
      localStorage.selectedIcon = JSON.stringify(sel);
      state.count = sel.length;
    }

    return {
      state,
      icons,
      gridIcons,
      iconClick,
    };
  },
};
